<script setup lang="js">
import { ref, onMounted } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useAutonodeStore } from '@/stores/autonode.store';
import { useProjectStore } from '@/stores/project.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useNodeStore } from '@/stores/node.store';
import _ from 'lodash';

const autonodeStore = useAutonodeStore();
const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const { direction_values, selectedDirectionIndex } = storeToRefs(autonodeStore);
const { project } = storeToRefs(projectStore);
const { bpNode } = storeToRefs(nodeStore);
const { closeModal } = useModal();
const projectId = useRouteParams('projectId');

const conditionValue = ref('');
const direction_id = ref(null);

function createOutput() {
  if (selectedDirectionIndex.value in direction_values.value) {
    direction_values.value = _.omit(direction_values.value, selectedDirectionIndex.value);
  }

  direction_values.value[conditionValue.value] = direction_id.value;
  closeModal();
}
onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (selectedDirectionIndex.value in direction_values.value) {
    conditionValue.value = selectedDirectionIndex.value;
    direction_id.value = direction_values.value[selectedDirectionIndex.value];
  }
});
</script>

<template>
  <ModalTemplateSide @submit="createOutput">
    <template v-slot:title>Добавить выходные данные</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Сравниваемое значение</label>
        <input type="text" class="form-control" v-model="conditionValue">
      </div>
      <div class="mt-3">
        <label class="form-label">Направление</label>
        <select v-model="direction_id" class="form-select">
          <option v-for="child_direction in bpNode.child_directions" :key="child_direction.next_bp_node_id" :value="child_direction.id">
            {{ child_direction.next_bp_node.current.node.name }}
          </option>
        </select>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
