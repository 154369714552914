<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { ref, watch } from 'vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import { useUserStore } from '@/stores/user.store';

const { closeModal } = useModal();
const userName = ref('');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const adminPanel = ref(false);
const projectCreate = ref(false);
const userStore = useUserStore();
const permissions = ref([]);

function updatePermissions() {
  if (adminPanel.value) {
    if (!permissions.value.includes('admin_panel')) {
      permissions.value.push('admin_panel');
    }
  } else {
    const index = permissions.value.indexOf('admin_panel');
    if (index > -1) {
      permissions.value.splice(index, 1);
    }
  }

  if (projectCreate.value) {
    if (!permissions.value.includes('create_project')) {
      permissions.value.push('create_project');
    }
  } else {
    const index = permissions.value.indexOf('create_project');
    if (index > -1) {
      permissions.value.splice(index, 1);
    }
  }
}

watch(adminPanel, updatePermissions);
watch(projectCreate, updatePermissions);

function createUser() {
  userStore
    .createUser(
      userName.value,
      email.value,
      password.value,
      confirmPassword.value,
      permissions.value
    )
    .then(() => {
      closeModal();
    });
}
</script>
<template>
  <ModalTemplateSide @submit="createUser" :disableButtons="userStore.createUserRequest.isLoading">
    <template v-slot:title>Добавить пользователя</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Фамилия и Имя пользователя</label>
        <input type="text" v-model="userName" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">E-mail</label>
        <input type="text" v-model="email" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">Пароль</label>
        <input type="password" v-model="password" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">Повторите пароль</label>
        <input type="password" v-model="confirmPassword" class="form-control" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Доступ к панели «Администрирование»</span>
        <basic-toggle-switch v-model="adminPanel" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Создание проекта</span>
        <basic-toggle-switch v-model="projectCreate" />
      </div>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
