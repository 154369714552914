<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { inputField, updateInputStaticRequest } = storeToRefs(inputFieldStore);
const locale = computed(() => projectStore.project.default_locale);

const title = ref({});
const italic = ref(false);
const bold = ref(false);
const size = ref('header');
const child_node_ids = ref([]);


onMounted(() => {
  inputFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (Object.keys(inputField.value).length) {
    title.value = inputField.value.static.title;
    if (inputField.value.static.italic) {
      italic.value = inputField.value.static.italic;
    }
    if (inputField.value.static.bold) {
      bold.value = inputField.value.static.bold;
    }
    if (inputField.value.static.size) {
      size.value = inputField.value.static.size;
    }
    inputField.value?.directional_statics.forEach((d) => {
      child_node_ids.value.push(d.direction.next_bp_node_id);
    });
  }
});

function updateStatic() {
  inputFieldStore
    .updateInputStatic(
      projectId.value,
      bpId.value,
      nodeId.value,
      inputField.value.id,
      title.value,
      italic.value,
      bold.value,
      size.value,
      child_node_ids.value
    )
    .then(() => {
      closeModal();
    });
}

function isErrorField(fieldName) {
  if (updateInputStaticRequest.value.error?.errors) {
    return updateInputStaticRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!updateInputStaticRequest.value.error?.errors) {
    return updateInputStaticRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="updateStatic" :disableButtons="updateInputStaticRequest.isLoading">
    <template v-slot:title>Редактировать заголовок для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование поля({{ locale.name }})</label>
        <input
          type="text"
          v-model="title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
        />
        <p v-if="isErrorField('title')" class="error-mesage">
          <span v-for="error in isErrorField('title')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>
      <!-- <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Курсив</label>
        <basic-toggle-switch v-model="italic" />
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Жирный текст</label>
        <basic-toggle-switch v-model="bold" />
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Размер:</label>
        <div class="static-sizes">
          <span @click="size = 'header'" :class="{checked: size == 'header'}">Заголовок</span>
          <span @click="size = 'note'" :class="{checked: size == 'note'}">Примечание</span>
        </div>
      </div> -->
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <div v-for="directional in inputField.directional_static" :key="directional.id">
          <b>{{ directional?.direction?.title?.[locale] || '' }}</b>
        </div>
      </div>
      <!-- <div class="mt-3">
        <label class="form-label">Порядок</label>
        <input type="number" v-model="order" class="form-control" min="0"/>
      </div> -->
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
