<script setup lang="js">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const bpStore = useBusinessProcessStore();
const { closeModal } = useModal();
const { createBPRequest } = storeToRefs(bpStore);

const name = ref('');
const description = ref('');
const type = ref('');

const typeOptions = [
  { value: null, text: '' },
  { value: 'legal_entity', text: 'Юр.лицо' },
  { value: 'individual', text: 'Физ.лицо' }
];

function createBP() {
  bpStore.createBP(name.value, description.value, type.value).then(() => closeModal());
}
function isErrorField(fieldName) {
  if (createBPRequest.value.error?.errors) {
    return createBPRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createBPRequest.value.error?.errors)) {
    return createBPRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createBP" :disableButtons="createBPRequest.isLoading">
    <template v-slot:title>Создать бизнес-процесс</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Системное наименование БП</label>
        <input type="text" v-model="name" class="form-control" :class="{'error': isErrorField('name')}" />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select class="form-select" v-model="type" :class="{ error: isErrorField('type') }">
          <option v-for="option in typeOptions" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание БП</label>
        <textarea v-model="description" class="form-control" :class="{'error': isErrorField('description')}"></textarea>
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>