<script setup lang="js">
import { ref, onMounted, computed } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useAutoformStore } from '@/stores/autoform.store';
import { useProjectStore } from '@/stores/project.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const autoformStore = useAutoformStore();
const projectStore = useProjectStore();
const { custom_variables, output_entities, outputVariableList, selectedOutputIndex } =
  storeToRefs(autoformStore);
const { project } = storeToRefs(projectStore);
const { closeModal } = useModal();
const projectId = useRouteParams('projectId');

const variable = ref(null);
const entity_id = ref(null);
const columns = ref({});

const availableOutputEntities = computed(() => {
  return outputVariableList.value
    .map((variable) => {
      const result = { ...variable };
      const match = variable.name.match(/^(?<entity>input_.+)\.(?<id>\d+)$/);

      if (match) {
        result.entity = match.groups.entity;
        result.id = match.groups.id;
      }

      return result;
    })
    .filter((v) => v.entity);
});

const selectedCustomVariable = computed(() => {
  return custom_variables.value.find((cv) => cv.name == variable.value) || {};
});

const selectedOutputEntity = computed(() => {
  return availableOutputEntities.value.find((e) => e.id == entity_id.value) || {};
});

function createOutput() {
  let output = {};
  output.entity_id = entity_id.value;
  output.variable = variable.value;
  output.columns = columns.value;
  output.entity = selectedOutputEntity.value.entity;

  if (selectedOutputIndex.value) {
    output_entities.value[selectedOutputIndex.value] = output;
  } else {
    output_entities.value.push(output);
  }

  closeModal();
}

onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (output_entities.value[selectedOutputIndex.value]) {
    let output_entity = { ...output_entities.value[selectedOutputIndex.value] };
    variable.value = output_entity.variable;
    columns.value = output_entity.columns;
    entity_id.value = output_entity.entity_id;
  }
});
</script>

<template>
  <ModalTemplateSide @submit="createOutput">
    <template v-slot:title>Добавить выходные данные</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Переменная</label>
        <select v-model="variable" class="form-select">
          <template v-for="variable in custom_variables" :key="variable.name">
            <option v-if="variable.type !== 'document'" :value="variable.name">
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="mt-3">
        <label class="form-label">Поле (в исполняемой системе)</label>
        <select v-model="entity_id" class="form-select">
          <template v-for="ent in availableOutputEntities" :key="ent.id">
            <option v-if="ent.type === selectedCustomVariable.type" :value="ent.id">
              {{ ent.title }}
            </option>
          </template>
        </select>
      </div>

      <div
        class="mt-4"
        v-if="selectedOutputEntity && selectedOutputEntity.entity === 'input_table'"
      >
        <h5>
          Столбцы (выберите соответствующие значения из переменной массива для записи в столбцы
          таблицы)
        </h5>
        <div class="mt-3" v-for="col in selectedOutputEntity.table_columns" :key="col.name">
          <label class="form-label">{{ col.title }}</label>
          <select v-model="columns[col.name]" class="form-select">
            <template v-for="arrCol in selectedCustomVariable.table_columns" :key="arrCol.name">
              <option :value="arrCol.name" v-if="arrCol.type === col.type">
                {{ arrCol.title }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
