<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import Multiselect from 'vue-multiselect';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { deleteTableLink } = useRegistryStore();
const registryStore = useRegistryStore();
const { regTableLinks, deleteTableLinkRequest } = storeToRefs(registryStore);
const regLink = ref({});

function deleteLink() {
  deleteTableLink(projectId.value, regLink.value.column_from.reg_table_id, regLink.value.id).then(
    () => {
      emit('save');
      closeModal();
    }
  );
}

function customLabel(link) {
  if (!link || !link.column_from || !link.column_to) {
    return '';
  }
  return `${link.column_from?.name}(Таблица ${link.column_from?.reg_table_id}) -> ${link.column_to?.name}(Таблица ${link.column_to?.reg_table_id})`;
}
function isHasError() {
  return deleteTableLinkRequest.value.error;
}
</script>

<template>
  <ModalTemplateSide @submit="deleteLink" :disableButtons="deleteTableLinkRequest.isLoading">
    <template v-slot:title>Удалить связь</template>
    <template v-slot:body>
      <label class="form-label mt-3">Выберите связь</label>
      <Multiselect
        v-model="regLink"
        :options="regTableLinks"
        placeholder="Выбрать..."
        :multiple="false"
        :custom-label="customLabel"
        track-by="id"
        class="multiselect-style"
      />
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
    </template>
    <template v-slot:submit>Удалить</template>
  </ModalTemplateSide>
</template>
