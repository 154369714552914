<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import Multiselect from 'vue-multiselect';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { createTableLink } = useRegistryStore();
const registryStore = useRegistryStore();
const { regTables, createTableLinkRequest } = storeToRefs(registryStore);
const regTable1 = ref({});
const regTableColumn1Id = ref({});
const regTable2 = ref({});
const regTableColumn2Id = ref({});

function createLink() {
  createTableLink(projectId.value, regTable1.value.id, regTableColumn1Id.value, regTable2.value.id, regTableColumn2Id.value).then(() => {
    emit('save');
    closeModal();
  });
}
function isHasError() {
  return createTableLinkRequest.value.error;
}
</script>

<template>
  <ModalTemplateSide @submit="createLink" :disableButtons="createTableLinkRequest.isLoading">
    <template v-slot:title>Связать колонки</template>
    <template v-slot:body>
      <div class="operation-modal_section-header">
        Первая колонка
      </div>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <Multiselect
          v-model="regTable1"
          :options="regTables"
          placeholder="Выбрать..."
          :multiple="false"
          label="id"
          track-by="id"
          class="multiselect-style"
        />
      </div>
      <div class="mt-3" v-if="regTable1.id">
        <label class="form-label">Выберите столбец (pk)</label>
        <select v-model="regTableColumn1Id" class="form-select" @change="selectColumn">
          <option :value="column.id" v-for="column in regTable1.columns">{{ column.name }}</option>
        </select>
      </div>
      <div class="operation-modal_section-header">
        Вторая колонка
      </div>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <Multiselect
          v-model="regTable2"
          :options="regTables"
          placeholder="Выбрать..."
          :multiple="false"
          label="id"
          track-by="id"
          class="multiselect-style"
        />
      </div>
      <div class="mt-3" v-if="regTable2.id">
        <label class="form-label">Выберите столбец (fk)</label>
        <select v-model="regTableColumn2Id" class="form-select" @change="selectColumn">
          <option :value="column.id" v-for="column in regTable2.columns">{{ column.name }}</option>
        </select>
      </div>
      <p v-if="isHasError()" class="error-mesage">
        {{ isHasError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
