<script setup lang="js">
import _ from 'lodash';
import { ref, onMounted, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';

import { useRoutePassportStore } from '@/stores/routePassport.store';
import { useProjectStore } from '@/stores/project.store';
import useModal from '@/stores/modal.store';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';

const projectId = useRouteParams('projectId');
const serviceId = useRouteParams('serviceId');
const routeId = useRouteParams('routeId');

const passportStore = useRoutePassportStore();
const projectStore = useProjectStore();

const { closeModal } = useModal();
const { passportBlocksReq, createOrUpdateBlockReq, currentPassportBlock, currentBlockGroup } =
  storeToRefs(passportStore);

const blockData = ref({
  type: 'text',
  title: {},
  content: {},
  highlight: false,
  group: currentBlockGroup.value
});
const localErrors = ref({});

const errors = computed(() => ({ ...createOrUpdateBlockReq.value.error, ...localErrors }));
const order = computed(() => {
  if (currentPassportBlock.value?.id) {
    return currentPassportBlock.value.order;
  }

  return (
    _.chain(passportBlocksReq.value.data || [])
      .filter({ group: currentBlockGroup.value })
      .maxBy('order')
      .get('order')
      .defaultTo(0)
      .value() + 1
  );
});
const title = computed(() => {
  const isEmptyTitle = _.chain(blockData.value.title).values().filter().isEmpty().value();

  return isEmptyTitle ? null : blockData.value.title;
});

onMounted(() => {
  if (currentPassportBlock.value?.id) {
    blockData.value = {
      ...currentPassportBlock.value,
      title: _.chain(projectStore.project.locales)
        .keyBy('id')
        .mapValues((l) => currentPassportBlock.value.title?.[l.id])
        .value()
    };
  }
});

watch(
  () => blockData.value.type,
  (newValue) => {
    let blockContent = null;

    if (newValue !== 'link') return;

    if (currentPassportBlock.value?.id) {
      blockContent = currentPassportBlock.value.content;
    }

    blockData.value.content = _.chain(projectStore.project.locales)
      .keyBy('id')
      .mapValues((l) => ({
        link: blockContent?.[l.id]?.link,
        linkText: blockContent?.[l.id]?.linkText
      }))
      .value();
  }
);

function sendRequest() {
  passportStore
    .createOrUpdateBlock(projectId.value, serviceId.value, routeId.value, {
      ...blockData.value,
      order: order.value,
      title: title.value
    })
    .then(() => {
      currentPassportBlock.value = {};
      closeModal();
    });
}
</script>

<template>
  <ModalTemplateSide @submit="sendRequest" :disableButtons="createOrUpdateBlockReq.isLoading">
    <template #title>
      <span v-if="_.isNumber(blockData.id)">Создать блок</span>
      <span v-else>Редактировать блок</span>
    </template>
    <template v-slot:body>
      <p v-if="blockData.group === 'main'">Главный раздел</p>
      <p v-if="blockData.group === 'additional'">Дополнительный раздел</p>

      <div class="my-3">
        <label class="form-label" for="passport-block-type">Тип блока</label>
        <select v-model="blockData.type" id="passport-block-type" class="form-control">
          <option value="text">Текст</option>
          <option value="link">Ссылка</option>
        </select>
      </div>
      <div class="mb-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Выделенный</label>
        <basic-toggle-switch v-model="blockData.highlight" />
      </div>
      <div v-for="locale in projectStore.project.locales" class="mb-3" :key="locale.id">
        <label class="form-label" for="passport-block-title">
          Заголовок блока ({{ locale.name }})
        </label>
        <input
          v-model="blockData.title[locale.id]"
          class="form-control"
          id="passport-block-title"
        />
      </div>

      <template v-for="locale in projectStore.project.locales" :key="locale.id">
        <div v-if="blockData.type === 'text'" class="mb-3">
          <label class="form-label" :for="`passport-block-text-${locale.id}`">
            Контент текстового блока ({{ locale.name }})
          </label>
          <textarea
            v-model="blockData.content[locale.id]"
            class="form-control"
            :id="`passport-block-text-${locale.id}`"
            rows="5"
          />
        </div>

        <div class="my-5" v-if="blockData.type === 'link'">
          <div class="mb-3">
            <label class="form-label" :for="`passport-block-link-${locale.id}`">
              Ссылка ({{ locale.name }})
            </label>
            <input
              v-model="blockData.content[locale.id].link"
              class="form-control"
              :id="`passport-block-link-${locale.id}`"
            />
            <p v-if="errors?.content?.[locale.id]?.link" class="error">
              {{ errors?.content?.[locale.id]?.link }}
            </p>
          </div>
          <div class="mb-3">
            <label class="form-label" :for="`passport-block-link-text-${locale.id}`">
              Текст ссылки ({{ locale.name }})
            </label>
            <input
              v-model="blockData.content[locale.id].linkText"
              class="form-control"
              :id="`passport-block-link-text-${locale.id}`"
            />
          </div>
        </div>
      </template>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
