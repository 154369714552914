<script setup lang="js">
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';

const { validateBP } = useBusinessProcessStore();
const { validateBPRequest, bp } = storeToRefs(useBusinessProcessStore());
const { closeModal } = useModal();
const projectId = useRouteParams('projectId');

function validateItem() {
  validateBP(projectId.value, bp.value.id).then(() => {});
}
function isHasError() {
  return validateBPRequest.value.error?.errors?.length;
}
</script>

<template>
  <ModalTemplateSide @submit="validateItem" :disableButtons="validateBPRequest.isLoading">
    <template v-slot:title>Валидировать бизнес-процесс</template>
    <template v-slot:body>
      <template v-if="validateBPRequest.isLoading">
        Подождите. Идет запрос...
      </template>
      <template v-if="isHasError()" class="error-mesage">
        <div class="table-responsive">
          <table class="table gray-table">
            <tr>
              <td>Сущность</td>
              <td>Название</td>
              <td>Ошибка</td>
              <td>Данные</td>
            </tr>
            <tr v-for="(error, index) in validateBPRequest.error?.errors" :key="error.id">
              <td>{{ error.entity }}</td>
              <td>{{ error.name }}</td>
              <td>{{ error.error }}</td>
              <td>{{ error?.payload }}</td>
            </tr>
          </table>
        </div>
      </template>
    </template>
    <template v-slot:submit>Валидировать</template>
  </ModalTemplateSide>
</template>
