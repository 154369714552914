import { computed, inject, ref } from 'vue';
import { defineStore } from 'pinia';
import _ from 'lodash';

import baseAxios from 'axios';

import { AXIOS } from '../const';
import * as api from '../router/endpoints';

const getReqDefaults = () => ({
  isLoading: false,
  isFinished: false,
  error: null,
  data: null
});

export const useRoutePassportStore = defineStore('routePassport', () => {
  const axios = inject(AXIOS) || baseAxios;

  const passportBlocksReq = ref(getReqDefaults());
  const createOrUpdateBlockReq = ref(getReqDefaults());
  const deleteBlockReq = ref(getReqDefaults());
  const changeOrderReq = ref(getReqDefaults());
  const copyPassportReq = ref(getReqDefaults());

  const currentPassportBlock = ref({});
  const currentBlockGroup = ref(null);

  const mainGroupBlocks = computed(() => {
    const reqData = passportBlocksReq.value.data || [];
    return reqData.filter((b) => b.group === 'main').sort((a,b) => a.order - b.order);
  });
  const additionalGroupBlocks = computed(() => {
    const reqData = passportBlocksReq.value.data || [];
    return reqData.filter((b) => b.group === 'additional').sort((a,b) => a.order - b.order);
  });

  function getPassportBlocks(projectId, serviceId, routeId) {
    passportBlocksReq.value = getReqDefaults();
    const req = passportBlocksReq.value;
    req.isLoading = true;

    return axios
      .get(api.passportBlocks({ projectId, serviceId, routeId }))
      .then((resp) => (req.data = resp.data))
      .catch((e) => {
        throw (req.error = e);
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createOrUpdateBlock(projectId, serviceId, routeId, data) {
    createOrUpdateBlockReq.value = getReqDefaults();
    const req = createOrUpdateBlockReq.value;
    req.isLoading = true;

    let url = api.passportBlocks({ projectId, serviceId, routeId });

    if (data.id) {
      url = api.passportBlockById({
        projectId,
        serviceId,
        routeId,
        passportBlockId: data.id
      });
    }

    return axios
      .post(url, data)
      .catch((e) => {
        throw (req.error = e);
      })
      .then(() => {
        getPassportBlocks(projectId, serviceId, routeId);

        if (!data.id) return;

        currentPassportBlock.value = {};
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deletePassportBlock(projectId, serviceId, routeId, passportBlockId) {
    deleteBlockReq.value = getReqDefaults();
    const req = deleteBlockReq.value;
    req.isLoading = true;

    return axios
      .delete(api.passportBlockById({ projectId, serviceId, routeId, passportBlockId }))
      .catch((e) => {
        throw (req.error = e);
      })
      .then(() => {
        currentPassportBlock.value = {};
        getPassportBlocks(projectId, serviceId, routeId);
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function copyPassport(projectId, serviceId, routeId, copyRouteIds) {
    copyPassportReq.value = getReqDefaults();
    const req = copyPassportReq.value;
    req.isLoading = true;

    return axios
      .post(
        api.copyPassportBlocks({ projectId, serviceId, routeId }),
        { route_ids: copyRouteIds }
      )
      .catch((e) => { throw req.error = e; })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    passportBlocksReq,
    createOrUpdateBlockReq,
    deleteBlockReq,
    changeOrderReq,
    copyPassportReq,
    currentPassportBlock,
    currentBlockGroup,

    mainGroupBlocks,
    additionalGroupBlocks,

    getPassportBlocks,
    createOrUpdateBlock,
    deletePassportBlock,
    copyPassport,
  };
});
