<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useProjectStore } from '@/stores/project.store';
import { useRoutePassportStore } from '@/stores/routePassport.store';
import { useServiceStore } from '@/stores/service.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';

const serviceStore = useServiceStore();
const projectStore = useProjectStore();
const routePassportStore = useRoutePassportStore();
const { closeModal } = useModal();

const { project } = storeToRefs(projectStore);
const { serviceRouteList: routeBusinessProcesses } = storeToRefs(serviceStore);
const { copyPassportReq } = storeToRefs(routePassportStore);

const serviceId = useRouteParams('serviceId');
const projectId = useRouteParams('projectId');
const currentRouteId = useRouteParams('routeId');

const selectedRoutes = ref([]);

onMounted(() => {
  projectStore.getProject(projectId.value);
  serviceStore.getServiceRouteList(projectId.value, serviceId.value);
});

function getRouteTitle(route) {
  return route.options.map((o) => o.title[project.value.default_locale]).join(',');
}

function checkAll() {
  selectedRoutes.value = routeBusinessProcesses.value.map((rbp) => rbp.route.id);
}

function copy() {
  routePassportStore.copyPassport(
    projectId.value,
    serviceId.value,
    currentRouteId.value,
    selectedRoutes.value
  ). then(() => {
    selectedRoutes.value = [];
    closeModal();
  });
}
</script>

<template>
  <ModalTemplateSide @submit="copy()" :disableButtons="copyPassportReq.isLoading">
    <template #title> Копирование паспортов по маршрутам </template>
    <template v-slot:body>
      <button class="my-2" @click="checkAll">Выделить все</button>

      <template
        v-for="routeBusinessProcess in routeBusinessProcesses"
        :key="routeBusinessProcess.id"
      >
        <div v-if="routeBusinessProcess.route.id != currentRouteId" class="mt-2">
          <label :for="routeBusinessProcess.route.id">
            <input
              :id="routeBusinessProcess.route.id"
              :value="routeBusinessProcess.route.id"
              type="checkbox"
              v-model="selectedRoutes"
            />
            {{ getRouteTitle(routeBusinessProcess.route) }}
          </label>
        </div>
      </template>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
