<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useVirtualMachineStore } from '@/stores/virtualMachine.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const virtualMachineStore = useVirtualMachineStore();
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { updateVirtualMachineRequest, virtualMachine, virtualMachineTypes } =
  storeToRefs(virtualMachineStore);

const ip = ref(null);
const user = ref(null);
const password = ref(null);
const domain = ref(null);
const type = ref(null);
const env_file = ref(null);
const composable_file = ref(null);

onMounted(() => {
  if (virtualMachine.value) {
    ip.value = virtualMachine.value.ip;
    domain.value = virtualMachine.value.domain;
    type.value = virtualMachine.value.type;
    user.value = virtualMachine.value.user;
    password.value = virtualMachine.value.password;
    env_file.value = virtualMachine.value.env_file_path;
    composable_file.value = virtualMachine.value.composable_file_path;
  }
});

function updateMachine() {
  virtualMachineStore
    .updateVirtualMachine(
      projectId.value,
      virtualMachine.value.id,
      ip.value,
      domain.value,
      type.value,
      user.value,
      password.value,
      env_file.value,
      composable_file.value
    )
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (updateVirtualMachineRequest.value.error?.errors) {
    return updateVirtualMachineRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!updateVirtualMachineRequest.value.error?.errors) {
    return updateVirtualMachineRequest.value.error;
  }
}

function uploadEnvFile(event) {
  const file = event.target.files[0];
  env_file.value = file;
}

function uploadComposableFile(event) {
  const file = event.target.files[0];
  composable_file.value = file;
}
</script>

<template>
  <ModalTemplateSide @submit="updateMachine" :disableButtons="updateVirtualMachineRequest.isLoading">
    <template v-slot:title>Редактировать вирт. машину</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">ip</label>
        <input type="text" v-model="ip" class="form-control" />
        <p v-if="isErrorField('ip')" class="error-mesage">
          <span v-for="error in isErrorField('ip')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Домен</label>
        <input type="text" v-model="domain" class="form-control" />
        <p v-if="isErrorField('domain')" class="error-mesage">
          <span v-for="error in isErrorField('domain')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select" :class="{ error: isErrorField('type') }">
          <option
            v-for="typeKey in Object.keys(virtualMachineTypes)"
            :key="typeKey"
            :value="typeKey"
          >
            {{ virtualMachineTypes[typeKey] }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Пользователь</label>
        <input type="text" v-model="user" class="form-control" />
        <p v-if="isErrorField('domain')" class="error-mesage">
          <span v-for="error in isErrorField('user')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Пароль</label>
        <input type="text" v-model="password" class="form-control" />
        <p v-if="isErrorField('domain')" class="error-mesage">
          <span v-for="error in isErrorField('domain')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">ENV файл</label>
        <div>
          <input type="file" @change="uploadEnvFile($event)" accept=".env" />
        </div>
        <p v-if="isErrorField('env_file')" class="error-mesage">
          <span v-for="error in isErrorField('env_file')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Compose файл</label>
        <div>
          <input type="file" @change="uploadComposableFile($event)" accept=".yml,.yaml" />
        </div>
        <p v-if="isErrorField('composable_file')" class="error-mesage">
          <span v-for="error in isErrorField('composable_file')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
