<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref, defineProps, reactive } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRouter } from 'vue-router';

const router = useRouter();
const { closeModal } = useModal();
const projectStore = useProjectStore();
const inputGeometriesStore = useInputGeometriesStore();
const { project } = storeToRefs(projectStore);
const { inputGeometryList, inputGeometriesChangeDirectionalRequest } = storeToRefs(inputGeometriesStore);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const selectAllChecked = ref(false);
const props = defineProps({
  selectedChildPivotId: Number,
  selectedChildBpNodeId: Number
});
const geoms = ref({
  creating_ids: [],
  removing_ids: []
});
const savedGeoms = ref([]);
const originalSavedGeoms = ref([]);
const selectedGeom = ref({});

onMounted(() => {
  inputGeometriesStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  let sGeoms = inputGeometryList.value.filter(isSavedGeom);
  sGeoms.forEach((geom) => {
    let geomId = geom.id;
    if (!savedGeoms.value.includes(geomId)) {
      originalSavedGeoms.value.push(geomId);
      savedGeoms.value.push(geomId);
    }
  });
});
function isSavedGeom(geom) {
  return geom.directional_geometries.some(
    (d) => d.direction?.next_bp_node_id == props.selectedChildBpNodeId
  );
}
function getTitle(geom) {
  return geom?.title[project.value.default_locale];
}
function toggleSelectAll() {
  const newState = !selectAllChecked.value;
  selectAllChecked.value = newState;
  inputGeometryList.value.forEach((geom) => {
    let geomId = geom.id;
    const isCurrentlyChecked = isCheckedGeom(geom);

    if (newState) {
      if (!isCurrentlyChecked) {
        if (!savedGeoms.value.includes(geomId)) {
          geoms.value.creating_ids.push(geomId);
        }
        const removingIndex = geoms.value.removing_ids.indexOf(geomId);
        if (removingIndex !== -1) {
          geoms.value.removing_ids.splice(removingIndex, 1);
        }
        selectedGeom[geomId] = true;
      }
    } else {
      if (isCurrentlyChecked) {
        if (savedGeoms.value.includes(geomId)) {
          geoms.value.removing_ids.push(geomId);
        }
        const creatingIndex = geoms.value.creating_ids.indexOf(geomId);
        if (creatingIndex !== -1) {
          geoms.value.creating_ids.splice(creatingIndex, 1);
        }
        selectedGeom[geomId] = false;
      }
    }
  });
}
function isCheckedGeom(geom) {
  let geomId = geom.id;
  return geoms.value.creating_ids.includes(geomId) || savedGeoms.value.includes(geomId);
}
function toggleGeometry(geom) {
  let geomId = geom.id;
  if (isCheckedGeom(geom)) {
    const savedIndex = savedGeoms.value.indexOf(geomId);
    if (savedIndex !== -1) {
      if (originalSavedGeoms.value.includes(geomId)) {
        geoms.value.removing_ids.push(geomId);
      }
      savedGeoms.value.splice(savedIndex, 1);
    }
    const creatingIndex = geoms.value.creating_ids.indexOf(geomId);
    if (creatingIndex !== -1) {
      geoms.value.creating_ids.splice(creatingIndex, 1);
    }
  } else {
    if (!geoms.value.creating_ids.includes(geomId)) {
      geoms.value.creating_ids.push(geomId);
    }
    const removingIndex = geoms.value.removing_ids.indexOf(geomId);
    if (removingIndex !== -1) {
      geoms.value.removing_ids.splice(removingIndex, 1);
    }
  }
  selectedGeom[geomId] = !selectedGeom[geomId];
}

function save() {
  inputGeometriesStore
    .changeDirectionalGeometries(
      projectId.value,
      bpId.value,
      nodeId.value,
      props.selectedChildPivotId,
      geoms.value
    )
    .then(() => {
      closeModal();
    });
}
function isRequestError() {
  return inputGeometriesChangeDirectionalRequest.value.error;
}
</script>

<template>
  <ModalTemplateSide @submit="save" :disableButtons="inputGeometriesChangeDirectionalRequest.isLoading">
    <template v-slot:title>Добавить геометрии для дочернего узла</template>
    <template v-slot:body>
      <div class="form-label d-flex align-items-center mt-3 pb-3" @click="toggleSelectAll">
        <div class="checkbox-btn" :class="{ checked: selectAllChecked }" />
        Выделить все
      </div>
      <p v-if="isRequestError()" class="error-mesage">
        {{ isRequestError() }}
      </p>
      <div class="mt-3" v-for="geom in inputGeometryList" :key="geom.id">
        <div class="d-flex align-items-center">
          <div class="checkbox-btn" :class="{ checked: isCheckedGeom(geom) }" @click="toggleGeometry(geom)" />
          <span class="ps-2">{{ getTitle(geom.geometry) }}</span>
        </div>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
