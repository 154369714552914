<script setup lang="js">
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';

const { fixateBP } = useBusinessProcessStore();
const { fixateBPRequest, bp } = storeToRefs(useBusinessProcessStore());
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();

function fixateItem() {
  fixateBP(projectId.value, bp.value.id).then(() => closeModal());
}
function isHasError() {
    return fixateBPRequest.value.error?.errors?.length;
}
</script>

<template>
  <ModalTemplateSide @submit="fixateItem" :disableButtons="fixateBPRequest.isLoading">
    <template v-slot:title>Зафиксировать бизнес-процесс</template>
    <template v-slot:body>
      <template v-if="fixateBPRequest.isLoading"> Подождите. Идет запрос... </template>
      <template v-if="isHasError()" class="error-mesage">
        <div class="table-responsive">
          <table class="table gray-table">
            <tr>
              <td>Сущность</td>
              <td>Название</td>
              <td>Ошибка</td>
              <td>Данные</td>
            </tr>
            <tr v-for="error in fixateBPRequest.error?.errors" :key="error.id">
              <td>{{ error.entity }}</td>
              <td>{{ error.name }}</td>
              <td>{{ error.error }}</td>
              <td>{{ error?.payload }}</td>
            </tr>
          </table>
        </div>
      </template>
    </template>
    <template v-slot:submit>Зафиксировать</template>
  </ModalTemplateSide>
</template>
