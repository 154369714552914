<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useNodeStore } from '@/stores/node.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import { storeToRefs } from 'pinia';

const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { roleList, directionTypeList, bpNode, createNodeRequest } = storeToRefs(nodeStore);
const title = ref({});
const note = ref("");
const name = ref("");
const role = ref("");
const direction_type = ref("");
const direction_field_title = ref({});
const direction_title = ref({});
const set_reject = ref(false);
const delegate = ref(false);

onMounted(() => {
  nodeStore.clearRequestVariables();
  if (!project.locales) {
    projectStore.getProject(projectId.value);
  }
});

function nodeName(bpNode){
  let title = bpNode?.current?.node?.title
  return title[project.value.default_locale];
}

function createNode() {
  if (direction_type.value != 'one') {
    direction_field_title.value = null;
  }
  if (!isShowDirectionTextField()) {
    direction_title.value = null;
  }
  nodeStore.createNode(projectId.value, bpId.value, title.value, name.value, note.value, role.value, direction_type.value, bpNode?.value.id, direction_field_title.value, direction_title.value, set_reject.value, delegate.value)
  .then(() => {
    closeModal();
  });
}

function isShowDirectionTextField() {
  if (Object.keys(bpNode.value).length) {
    if (bpNode.value.current.node.direction_type == "one") {
      return true
    }
  }
  return false
}
function isErrorField(fieldName) {
  if (createNodeRequest.value.error?.errors) {
    return createNodeRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createNodeRequest.value.error?.errors)) {
    return createNodeRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createNode" :disableButtons="createNodeRequest.isLoading">
    <template v-slot:title>
      <span v-if="Object.keys(bpNode).length">
        Создать дочерний узел от {{ nodeName(bpNode) }}
      </span>
      <span v-else>Создать узел</span>
    </template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Системное название узла({{ locale.name }})</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{'error': isErrorField('title')}" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Название узла</label>
        <textarea type="text" v-model="name" class="form-control" :class="{'error': isErrorField('name')}"></textarea>
      </div>
      <p v-if="isErrorField('name')" class="error-mesage">
        <span v-for="error in isErrorField('name')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Примечание (не обязательно)</label>
        <textarea type="text" v-model="note" class="form-control" :class="{'error': isErrorField('note')}"></textarea>
      </div>
      <p v-if="isErrorField('note')" class="error-mesage">
        <span v-for="error in isErrorField('note')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Роль</label>
        <select v-model="role" class="form-select" :class="{'error': isErrorField('role')}">
          <option v-for="roleKey in Object.keys(roleList)" :key="roleKey" :value="roleKey">
            {{ roleList[roleKey] }}
          </option>
        </select>
      </div>
      <p v-if="isErrorField('role')" class="error-mesage">
        <span v-for="error in isErrorField('role')">{{ error }}</span>
      </p>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Установить статус отказано</label>
        <basic-toggle-switch v-model="set_reject" />
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Делегирование</label>
        <basic-toggle-switch v-model="delegate" />
      </div>
      <div class="mt-3">
        <label class="form-label">Направление</label>
        <select v-model="direction_type" class="form-select" :class="{'error': isErrorField('direction_type')}">
          <option v-for="directionTypeKey in Object.keys(directionTypeList)" :key="directionTypeKey" :value="directionTypeKey">
            {{ directionTypeList[directionTypeKey] }}
          </option>
        </select>
      </div>
      <p v-if="isErrorField('direction_type')" class="error-mesage">
        <span v-for="error in isErrorField('direction_type')">{{ error }}</span>
      </p>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id" v-if="direction_type == 'one'">
        <label class="form-label">Название направления({{ locale.name }})</label>
        <input type="text" v-model="direction_field_title[locale.id]" class="form-control" :class="{'error': isErrorField('direction_field_title')}" />
      </div>
      <p v-if="isErrorField('direction_field_title')" class="error-mesage">
        <span v-for="error in isErrorField('direction_field_title')">{{ error }}</span>
      </p>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id" v-if="isShowDirectionTextField()">
        <label class="form-label">Текст при выборе направления({{ locale.name }})</label>
        <input type="text" v-model="direction_title[locale.id]" class="form-control" :class="{'error': isErrorField('direction_title')}" />
      </div>
      <p v-if="isErrorField('direction_title')" class="error-mesage">
        <span v-for="error in isErrorField('direction_title')">{{ error }}</span>
      </p>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
