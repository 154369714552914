<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useServiceStore } from '@/stores/service.store';
import { useProjectStore } from '@/stores/project.store';
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useRouter } from 'vue-router';
import Multiselect from 'vue-multiselect';

const serviceStore = useServiceStore();
const projectStore = useProjectStore();
const businessProcessStore = useBusinessProcessStore();
const projectId = useRouteParams('projectId');
const serviceId = useRouteParams('serviceId');
const { project } = storeToRefs(projectStore);
const { serviceCriterions } = storeToRefs(serviceStore);
const { bpList } = storeToRefs(businessProcessStore);
const { closeModal } = useModal();
const businessProcess = ref('');
const router = useRouter();
const selectedCriterionValues = ref([]);

onMounted(() => {
  projectStore.getProject(projectId.value);
  serviceStore.getServiceCriterion(projectId.value, serviceId.value);
  businessProcessStore.getBPList();
});

function handleChangeCriterions(event) {
  const value = event.target.value;
  selectedCriterionValues.value.push(value);
}

function createServiceRoute() {
  serviceStore
    .createRoute(
      projectId.value,
      serviceId.value,
      selectedCriterionValues.value,
      businessProcess.value?.id
    )
    .then(() => {
      closeModal();
      router.push({ name: 'service-route' });
      serviceStore.getServiceRouteList(projectId.value, serviceId.value);
    });
}
</script>

<template>
  <ModalTemplateSide @submit="createServiceRoute" :disableButtons="serviceStore.createRouteRequest.isLoading">
    <template v-slot:title>Создать маршрут</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Бизнес-процесс</label>
        <Multiselect
          v-model="businessProcess"
          :options="bpList"
          placeholder="Выбрать..."
          :multiple="false"
          label="name"
          track-by="id"
          class="multiselect-style"
        />
      </div>
      <div class="mt-3" v-for="criterion in serviceCriterions" :key="criterion.id">
        <label class="form-label">{{ criterion.title[project.default_locale] }}</label>
        <select @change="handleChangeCriterions($event)" class="form-select">
          <option :value="null" selected redonly></option>
          <option v-for="option in criterion.options" :key="option.id" :value="option.id">
            {{ option.title[project.default_locale] }}
          </option>
        </select>
      </div>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>