<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import Multiselect from 'vue-multiselect';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { deleteRegTable } = useRegistryStore();
const registryStore = useRegistryStore();
const { regTables, deleteRegTableRequest } = storeToRefs(registryStore);
const regTableId = ref(null);

function deleteTable() {
  deleteRegTable(projectId.value, regTableId.value.id).then(() => {
    emit('save');
    closeModal();
  });
}
</script>

<template>
  <ModalTemplateSide @submit="deleteTable" :disableButtons="deleteRegTableRequest.isLoading">
    <template v-slot:title>Удалить таблицу</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <Multiselect
          v-model="regTableId"
          :options="regTables"
          placeholder="Выбрать..."
          :multiple="false"
          label="id"
          track-by="id"
          class="multiselect-style"
        />
      </div>
    </template>
    <template v-slot:submit>Удалить</template>
  </ModalTemplateSide>
</template>
