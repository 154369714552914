<script setup lang="js">
import _ from 'lodash';
import { onMounted, computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';

import { useServiceStore } from '@/stores/service.store';
import { useProjectStore } from '@/stores/project.store';
import { useCriterionStore } from '@/stores/criterion.store';
import { useRoutePassportStore } from '@/stores/routePassport.store';
import useModal from '@/stores/modal.store';

import PassportBlockModal from '@/components/modals/PassportBlockModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import PassportCopyModal from '@/components/modals/PassportCopyModal.vue';

const serviceId = useRouteParams('serviceId');
const projectId = useRouteParams('projectId');
const routeId = useRouteParams('routeId');

const serviceStore = useServiceStore();
const projectStore = useProjectStore();
const criterionStore = useCriterionStore();
const passportStore = useRoutePassportStore();

const { project } = storeToRefs(projectStore);
const { selectedOption } = storeToRefs(criterionStore);
const { service, serviceRouteList, serviceCriterions } = storeToRefs(serviceStore);
const { mainGroupBlocks, additionalGroupBlocks, currentBlockGroup, currentPassportBlock } =
  storeToRefs(passportStore);

const { activeModal, openModal, closeModal } = useModal();

const showContextMenu = ref(false);
const menu = ref(null);

const routeCriterionsList = computed(() => {
  const bpRoute = serviceRouteList.value.find((rbp) => rbp.route.id === +routeId.value);
  return _.chain(serviceCriterions.value)
    .keyBy((c) => c?.title?.[project.default_locale || 'ru'])
    .mapValues((c) => {
      const opt = bpRoute?.route?.options?.find((rc) => rc.criterion_id === c.id);
      return opt?.title?.[project.default_locale || 'ru'];
    })
    .value();
});

onMounted(() => {
  projectStore.getProject(projectId.value);
  serviceStore.getService(serviceId.value, projectId.value);
  serviceStore.getServiceRouteList(projectId.value, serviceId.value, selectedOption.value);
  serviceStore.getServiceCriterion(projectId.value, serviceId.value);
  passportStore.getPassportBlocks(projectId.value, serviceId.value, routeId.value);
});

function createBlock(group) {
  currentBlockGroup.value = group;
  openModal('blockModal');
}

function transformText(text) {
  const replaceMap = new Map();
  replaceMap.set(
    /<(\s*?)link(\s*?)=(\s*?)"(?<link>.*)"(\s*?)>(?<linkText>.*)<(\s*?)\/(\s*?)link(\s*?)>/g,
    '<a href="$<link>">$<linkText></a>'
  );
  replaceMap.set(
    /<(\s*?)color(\s*?)=(\s*?)"(?<color>#[0-9a-fA-F]{6})"(\s*?)>(?<coloredText>.*)<(\s*?)\/(\s*?)color(\s*?)>/g,
    '<span style="color: $<color>">$<coloredText></span>'
  );
  replaceMap.set(
    /<(\s*?)size(\s*?)=(\s*?)"(?<size>\d+)"(\s*?)>(?<sizedText>.*)<(\s*?)\/(\s*?)size(\s*?)>/g,
    '<span style="font-size: $<size>px">$<sizedText></span>'
  );
  replaceMap.set(
    /<(\s*?)bold(\s*?)>(?<boldText>.*)<(\s*?)\/(\s*?)bold(\s*?)>/g,
    '<span style="font-weight: bold">$<boldText></span>'
  );

  let resultText = text;
  replaceMap.forEach((substr, regExp) => {
    resultText = resultText.replaceAll(regExp, substr);
  });

  return resultText;
}

function menuHandler(block, event) {
  currentPassportBlock.value = block;
  showContextMenu.value = true;
  setTimeout(() => {
    const screenWidth = event.view.visualViewport.width;
    const screenHeight = event.view.visualViewport.height;

    const moveLeft = screenWidth - event.pageX < menu.value.offsetWidth;
    const moveTop = screenHeight - event.pageY < menu.value.offsetHeight;

    menu.value.style.left = `${event.pageX - (moveLeft ? menu.value.offsetWidth : 0)}px`;
    menu.value.style.top = `${event.pageY - (moveTop ? menu.value.offsetHeight : 0)}px`;
  }, 0);
}

function editMenuHandler() {
  showContextMenu.value = false;
  openModal('blockModal');
}

function deleteMenuHandler() {
  showContextMenu.value = false;
  openModal('deleteModal');
}

function getTitleForDeletingBlock() {
  const deletingBlockTitle = currentPassportBlock.value.title;

  if (!deletingBlockTitle) return '';

  return deletingBlockTitle[project.value.default_locale];
}

function deleteBlock() {
  passportStore.deletePassportBlock(
    projectId.value,
    serviceId.value,
    routeId.value,
    currentPassportBlock.value.id
  );
  closeModal();
}

function closeMenu() {
  showContextMenu.value = false;
  currentPassportBlock.value = {};
}
</script>

<template>
  <div id="service-route-passport-view">
    <div class="container-fluid">
      <div class="d-flex mt-3 page-header align-items-center">
        <RouterLink :to="{ name: 'service-route' }" class="back-btn" />
        <h1 class="m-0 flex-grow-1" v-if="service.title">
          {{ service.title[project.default_locale] }}
        </h1>

        <button @click="openModal('copyPassportModal')" class="orange-btn mr-3">Скопировать</button>
        <a
          class="orange-btn"
          target="_blank"
          href="https://book.kaya.kz/books/instrukciia-po-faktorio/page/rabota-s-novymi-pasportami"
          >Инструкция</a
        >
      </div>
      <div class="route-passport-content mt-4">
        <div class="criterions px-4">
          <p v-for="(opt, crit) in routeCriterionsList" :key="crit">
            <b>{{ crit }}</b> : {{ opt }}
          </p>
        </div>
        <div class="d-flex">
          <div class="col-8 px-4 border-end border-secondary passportGroup-main">
            <div
              v-for="block in mainGroupBlocks"
              class="mb-3 passportBlock"
              :class="{ passportBlock_highlighted: block.highlight }"
              :key="block.id"
            >
              <div @click="(event) => menuHandler(block, event)" class="passportBlock-button">
                <img src="@/assets/img/context-menu-ico-hover.svg" alt="" />
              </div>
              <div v-if="block.title" class="passportBlock-title mb-3">
                {{ block.title[project.default_locale] }}
              </div>
              <div v-if="block.type === 'text'">
                <p
                  class="passportBlock-content passportBlock-content__text"
                  v-html="transformText(block.content[project.default_locale])"
                />
              </div>
              <div v-if="block.type === 'link'">
                <a :href="block.content[project.default_locale].link">
                  {{ block.content[project.default_locale].linkText }}
                </a>
              </div>
            </div>
            <div class="orange-btn" @click="createBlock('main')">Добавить блок</div>
          </div>
          <div class="col-4 px-4 passportGroup-additional">
            <div
              v-for="block in additionalGroupBlocks"
              class="mb-3 passportBlock"
              :class="{ highlight: block.highlight }"
              :key="block.id"
            >
              <div @click="(event) => menuHandler(block, event)" class="passportBlock-button">
                <img src="@/assets/img/context-menu-ico-hover.svg" alt="" />
              </div>
              <div v-if="block.title" class="passportBlock-title mb-1">
                {{ block.title[project.default_locale] }}
              </div>
              <div v-if="block.type === 'text'">
                <p
                  class="passportBlock-content passportBlock-content__text"
                  v-html="transformText(block.content[project.default_locale])"
                />
              </div>
              <div v-if="block.type === 'link'">
                <a :href="block.content[project.default_locale].link">
                  {{ block.content[project.default_locale].linkText }}
                </a>
              </div>
            </div>
            <div class="orange-btn" @click="createBlock('additional')">Добавить блок</div>
          </div>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <div v-show="showContextMenu" class="menu-wrapper">
        <div class="menu-overlay" @click="closeMenu"></div>
        <div ref="menu" class="menu">
          <div class="menu-item menu-item__edit" @click="editMenuHandler">
            <div class="menu-item-icon" />
            <span>Редактировать</span>
          </div>
          <div class="menu-item menu-item__delete" @click="deleteMenuHandler">
            <div class="menu-item-icon" />
            <span>Удалить</span>
          </div>
        </div>
      </div>
    </Teleport>
    <PassportBlockModal v-if="activeModal === 'blockModal'" />
    <PassportCopyModal v-if="activeModal === 'copyPassportModal'" />
    <DeleteModal
      v-if="activeModal === 'deleteModal'"
      :itemText="'этот блок'"
      :title="getTitleForDeletingBlock()"
      @confirmDelete="deleteBlock"
    />
  </div>
</template>
