<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useProjectStore } from '@/stores/project.store';
import { useNodeStore } from '@/stores/node.store';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import InputGeometryChildNodeModal from '@/components/modals/InputGeometryChildNodeModal.vue';

const inputGeometriesStore = useInputGeometriesStore();
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const nodeStore = useNodeStore();
const { openModal, activeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { inputGeometryList } = storeToRefs(inputGeometriesStore);
const { bpNode } = storeToRefs(nodeStore);
const selectedChildBpNode = ref({});
const selectedChildBpNodeId = ref('');
const selectedChildPivotId = ref('');
const hasUnstableInputGeom = computed(() => {
  return inputGeometryList.value.some((inputGeom) => !inputGeom.stable);
});

onMounted(() => {
  inputGeometriesStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputGeometriesStore.getInputGeometriesList(projectId.value, bpId.value, nodeId.value);
  nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
});
function selectNode(direction) {
  selectedChildBpNode.value = direction.next_bp_node;
  selectedChildBpNodeId.value = direction.next_bp_node_id;
  selectedChildPivotId.value = direction.id;
}
function nodeName(title) {
  return title[project.value.default_locale];
}
function getTitle(geom) {
  return geom?.geometry?.title[project.value.default_locale];
}
function showDoc(doc) {
  return doc?.directional_geometries.some(
    (d) => d.direction.next_bp_node_id == selectedChildBpNodeId.value
  );
}
function fieldType(type) {
  const typeMapping = {
    Polygon: 'Полигон',
    LineString: 'Линия'
  };
  return typeMapping[type] || 'Точка';
}
</script>

<template>
  <div class="a">
    <div class="d-flex align-items-center justify-content-between input-fields__btns mt-2">
      <div class="text-uppercase col-md-4 px-3">
        <b>Настроить геометрии для дочерних узлов</b>
      </div>
      <div class="d-flex align-items-center justify-content-end flex-wrap mr-3 col-md-8 px-3">
        <RouterLink
          :to="{
            name: 'input-geometry',
            params: {
              projectId: projectId,
              bpId: bpId,
              nodeId: nodeId
            }
          }"
          class="gray-btn"
        >
          Закрыть
        </RouterLink>
      </div>
    </div>
    <div class="bp-section-content_child-links">
      <div
        class="bp-section-content_child-link"
        v-for="childDirection in bpNode.child_directions"
        :key="childDirection.next_bp_node_id"
        @click="selectNode(childDirection)"
        :class="{ active: selectedChildBpNodeId == childDirection.next_bp_node_id }"
      >
        <span :class="{ 'childNode-fixation_circle': hasUnstableInputGeom }">{{
          nodeName(childDirection.next_bp_node.current.node.title)
        }}</span>
      </div>
    </div>
    <div class="bp-section-content_node-name px-3 my-3" v-if="selectedChildBpNodeId">
      <b>{{ nodeName(selectedChildBpNode.current.node.title) }}</b>
    </div>
    <button
      class="simple-btn mt-3 mb-3 ml-3"
      @click="openModal('addGeomForChildsModal')"
      v-if="selectedChildBpNodeId"
    >
      <span class="orange-plus" />
      Добавить
    </button>
  </div>
  <div class="table-responsive pb-5 child-nodes_table">
    <table class="table gray-table" v-if="selectedChildBpNodeId">
      <thead>
        <tr>
          <th>Наименование геометрии</th>
          <th>Тип</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="inputGeometry in inputGeometryList" :key="inputGeometry.id">
          <tr
            v-if="showDoc(inputGeometry)"
            :class="{
              'fixation-row-style': !inputGeometry.stable
            }"
          >
            <td>
              <div class="d-flex align-items-center">
                <div v-if="!inputGeometry.stable" class="fixation-circle-style"></div>
                <span class="name ps-2">{{ getTitle(inputGeometry) }}</span>
              </div>
            </td>
            <td>{{ fieldType(inputGeometry.geometry.type) }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <InputGeometryChildNodeModal
    v-if="activeModal === 'addGeomForChildsModal'"
    :selectedChildPivotId="selectedChildPivotId"
    :selectedChildBpNodeId="selectedChildBpNodeId"
  />
</template>
