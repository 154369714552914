<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref, defineProps } from 'vue';
import { useRouteParams } from '@vueuse/router';
import BasicToggleSwitch from '@/components/toggle-switch.vue';

const { closeModal } = useModal();
const projectStore = useProjectStore();
const inputDocumentsStore = useInputDocumentsStore();
const { inputDocumentsEditRequest } = storeToRefs(inputDocumentsStore);
const { project } = storeToRefs(projectStore);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const maxSizes = [
  { id: null, text: '' },
  { id: 1, value: 10 },
  { id: 2, value: 50 },
  { id: 3, value: 100 }
];
const props = defineProps({
  selectedDocument: Object
});
onMounted(() => {
  inputDocumentsStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
});

function updateDoc() {
  inputDocumentsStore
    .inputDocUpdate(
      projectId.value,
      bpId.value,
      nodeId.value,
      props.selectedDocument.id,
      props.selectedDocument.document.title,
      props.selectedDocument.document.is_result,
      props.selectedDocument.document.max_size,
      props.selectedDocument.document.required,
      props.selectedDocument.signable
    )
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (inputDocumentsEditRequest.value.error?.errors) {
    return inputDocumentsEditRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(inputDocumentsEditRequest.value.error?.errors)) {
    return inputDocumentsEditRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="updateDoc" :disableButtons="inputDocumentsEditRequest.isLoading">
    <template v-slot:title>Редактировать документ для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование документа на {{ locale.name }}</label>
        <input
          type="text"
          v-model="props.selectedDocument.document.title[locale.id]"
          class="form-control"
          :class="{'error': isErrorField('title')}"
        />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')" :key="error">
          {{ error }}
        </span>
      </p>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Обязательность</span>
        <basic-toggle-switch v-model="props.selectedDocument.document.required" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Подпись</span>
        <basic-toggle-switch v-model="props.selectedDocument.signable" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Отображать результат Завителю</span>
        <basic-toggle-switch v-model="props.selectedDocument.document.is_result" />
      </div>
      <div class="mt-3">
        <label class="form-label">Максимальный размер</label>
        <select v-model="props.selectedDocument.document.max_size" class="form-select" :class="{'error': isErrorField('max_size')}">
          <option v-for="size in maxSizes" :key="size.id" :value="size.id">
            {{ size.value }}
          </option>
        </select>
        <p v-if="isErrorField('title')" class="error-mesage">
          <span v-for="error in isErrorField('title')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <div v-for="directional in (props.selectedDocument?.directional_docs || [])" :key="directional.id">
          <b>{{ directional?.direction?.next_bp_node?.current?.node?.name }}</b>
        </div>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
