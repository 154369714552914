<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRouteParams } from '@vueuse/router';
import { defineProps } from 'vue';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import BasicToggleSwitch from '@/components/toggle-switch.vue';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const inputTableId = useRouteParams('inputTableId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { typeList, updateColumnRequest } = storeToRefs(inputFieldStore);
const props = defineProps({
  column: Object
});
const title = ref({});
const type = ref("");
const required = ref(false);

onMounted(() => {
  inputFieldStore.clearRequestVariables();
  if (!project.locales) {
    projectStore.getProject(projectId.value);
  }
  if (Object.keys(props.column).length) {
    title.value = props.column.column.title;
    type.value = props.column.column.type;
    required.value = props.column.column?.required || false;
  }
});

function updateColumn() {
  inputFieldStore.updateColumn(projectId.value, bpId.value, nodeId.value, inputTableId.value, props.column.id, title.value, type.value, required.value)
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (updateColumnRequest.value.error?.errors) {
    return updateColumnRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(updateColumnRequest.value.error?.errors)) {
    return updateColumnRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="updateColumn" :disableButtons="updateColumnRequest.isLoading">
    <template v-slot:title>Редактировать столбец</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование ({{ locale.name }})</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{ 'error': isErrorField('title') }" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select" :class="{ 'error': isErrorField('type') }">
          <option v-for="typeKey in Object.keys(typeList)" :key="typeKey" :value="typeKey">
            {{ typeList[typeKey] }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
