<script setup lang="js">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAutonodeStore } from '@/stores/waitingAutonode.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const autonodeStore = useAutonodeStore();
const { variableTypeList, custom_variables, newUuid, generateUuidRequest } = storeToRefs(autonodeStore);
const { closeModal } = useModal();

const title = ref(null);
const type = ref(null);
const default_value = ref(null);

function createVariable() {
  autonodeStore.generateUuid().then(() => {
    custom_variables.value.push({
      name: newUuid.value,
      title: title.value,
      type: type.value,
      default_value: default_value.value,
    })
    closeModal();
  });
}

function showDefaultValue() {
  let types = ["double", "boolean", "identificator", "date", "text"];
  if (type.value && types.includes(type.value)) {
    return true;
  }
  return false;
}
</script>

<template>
  <ModalTemplateSide @submit="createVariable">
    <template v-slot:title>Создать переменную</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Наименование</label>
        <input type="text" v-model="title" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select">
          <option v-for="typeKey in Object.keys(variableTypeList)" :key="typeKey" :value="typeKey">
            {{ variableTypeList[typeKey] }}
          </option>
        </select>
      </div>
      <div class="mt-3" v-if="showDefaultValue()">
        <label class="form-label">Значение по умолчанию</label>
        <input type="date" v-model="default_value" class="form-control" v-if="type == 'date'" />
        <input type="text" v-model="default_value" class="form-control" v-if="type == 'text'" />
        <select v-model="default_value" class="form-select" v-else-if="type == 'boolean'">
          <option :value="true">
            Истина
          </option>
          <option :value="false">
            Ложь
          </option>
        </select>
        <input type="number" v-model="default_value" class="form-control" v-else />
      </div>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
