<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref, defineProps, reactive } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRouter } from 'vue-router';

const router = useRouter();
const { closeModal } = useModal();
const projectStore = useProjectStore();
const inputDocumentsStore = useInputDocumentsStore();
const { project } = storeToRefs(projectStore);
const { inputDocumentsList } = storeToRefs(inputDocumentsStore);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const selectAllChecked = ref(false);
const props = defineProps({
  selectedChildPivotId: Number,
  selectedChildBpNodeId: Number
});
const docs = ref({
  creating_ids: [],
  removing_ids: []
});
const savedDocs = ref([]);
const originalSavedDocs = ref([]);
const selectedDoc = ref({});

onMounted(() => {
  let sDocs = inputDocumentsList.value.filter(isSavedDoc);
  sDocs.forEach((doc) => {
    let docId = doc.id;
    if (!savedDocs.value.includes(docId)) {
      originalSavedDocs.value.push(docId);
      savedDocs.value.push(docId);
    }
  });
});
function isSavedDoc(doc) {
  return doc.directional_docs.some(
    (d) => d.direction?.next_bp_node_id == props.selectedChildBpNodeId
  );
}
function getTitle(doc) {
  const keys = Object.keys(doc?.title);
  return doc?.title[keys[0]];
}
function toggleSelectAll() {
  const newState = !selectAllChecked.value;
  selectAllChecked.value = newState;
  inputDocumentsList.value.forEach((doc) => {
    let docId = doc.id;
    const isCurrentlyChecked = isCheckedDoc(doc);

    if (newState) {
      if (!isCurrentlyChecked) {
        if (!savedDocs.value.includes(docId)) {
          docs.value.creating_ids.push(docId);
        }
        const removingIndex = docs.value.removing_ids.indexOf(docId);
        if (removingIndex !== -1) {
          docs.value.removing_ids.splice(removingIndex, 1);
        }
        selectedDoc[docId] = true;
      }
    } else {
      if (isCurrentlyChecked) {
        if (savedDocs.value.includes(docId)) {
          docs.value.removing_ids.push(docId);
        }
        const creatingIndex = docs.value.creating_ids.indexOf(docId);
        if (creatingIndex !== -1) {
          docs.value.creating_ids.splice(creatingIndex, 1);
        }
        selectedDoc[docId] = false;
      }
    }
  });
}
function isCheckedDoc(doc) {
  let docId = doc.id;
  return docs.value.creating_ids.includes(docId) || savedDocs.value.includes(docId);
}
function toggleDoc(doc) {
  let docId = doc.id;
  if (isCheckedDoc(doc)) {
    const savedIndex = savedDocs.value.indexOf(docId);
    if (savedIndex !== -1) {
      if (originalSavedDocs.value.includes(docId)) {
        docs.value.removing_ids.push(docId);
      }
      savedDocs.value.splice(savedIndex, 1);
    }
    const creatingIndex = docs.value.creating_ids.indexOf(docId);
    if (creatingIndex !== -1) {
      docs.value.creating_ids.splice(creatingIndex, 1);
    }
  } else {
    if (!docs.value.creating_ids.includes(docId)) {
      docs.value.creating_ids.push(docId);
    }
    const removingIndex = docs.value.removing_ids.indexOf(docId);
    if (removingIndex !== -1) {
      docs.value.removing_ids.splice(removingIndex, 1);
    }
  }
  selectedDoc[docId] = !selectedDoc[docId];
}

function save() {
  inputDocumentsStore
    .changeDirectionalDocs(
      projectId.value,
      bpId.value,
      nodeId.value,
      props.selectedChildPivotId,
      docs.value
    )
    .then(() => {
      closeModal();
    });
}
</script>

<template>
  <ModalTemplateSide @submit="save" :disableButtons="inputDocumentsStore.inputDocumentsChangeDirectionalRequest.isLoading">
    <template v-slot:title>Добавить документы для дочернего узла</template>
    <template v-slot:body>
      <div class="form-label d-flex align-items-center mt-3 pb-3" @click="toggleSelectAll">
        <div class="checkbox-btn" :class="{ checked: selectAllChecked }" />
        Выделить все
      </div>

      <div class="mt-3" v-for="doc in inputDocumentsList" :key="doc.id">
        <div class="d-flex align-items-center">
          <div
            class="checkbox-btn"
            :class="{ checked: isCheckedDoc(doc) }"
            @click="toggleDoc(doc)"
          />
          <span class="ps-2">{{ getTitle(doc.document) }}</span>
        </div>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
