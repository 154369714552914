import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useUserStore = defineStore('user', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref('');
  const paginatedUsersList = ref([]);
  const userList = ref([]);
  const usersProjectList = ref([]);
  const user = ref({});
  const currentPage = ref(1);

  const getPaginatedUserListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getUserListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createUserRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getUsersProjectListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getUserRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const userDeleteRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const addProjectsRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const changePermissionsRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null,
    projectId: null,
    permissionName: null
  });

  function getPaginatedUsersList(page = 1) {
    getPaginatedUserListRequest.value = {};
    const req = getPaginatedUserListRequest.value;
    req.isLoading = true;
    currentPage.value = page;

    return axios
      .get(api.users(), {
        params: { page, search: searchName.value }
      })
      .then((resp) => {
        paginatedUsersList.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getUserList() {
    getUserListRequest.value = {};
    const req = getUserListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.users(), {
        params: { paginated: 0, search: searchName.value }
      })
      .then((resp) => {
        userList.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createUser(name, email, password, password_confirmation, permissions) {
    createUserRequest.value = {};
    const req = createUserRequest.value;
    req.isLoading = true;

    return axios
      .post(api.users(), {
        name,
        email,
        password,
        password_confirmation,
        permissions
      })
      .then(() => {
        getPaginatedUsersList(currentPage.value);
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getUser(userId) {
    getUserRequest.value = {};
    const req = getUserRequest.value;
    req.isLoading = true;

    return axios
      .get(api.userById({ userId }))
      .then((resp) => {
        user.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateUser(userId, name, email, password, password_confirmation, permissions) {
    getPaginatedUserListRequest.value = {};
    const req = getPaginatedUserListRequest.value;
    req.isLoading = true;

    return axios
      .put(api.userById({ userId }), { name, email, password, password_confirmation, permissions })
      .then(() => {
        getPaginatedUsersList(currentPage.value);
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function userDelete(userId) {
    userDeleteRequest.value = {};
    const req = userDeleteRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.userById({ userId }))
      .then(() => {
        getPaginatedUsersList(1);
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getUsersProjectList(userId) {
    getUsersProjectListRequest.value = {};
    const req = getUsersProjectListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.userProjects({ userId }), {
        params: { search: searchName.value }
      })
      .then((resp) => {
        usersProjectList.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function addProjects(userId, project_ids) {
    addProjectsRequest.value = {};
    const req = addProjectsRequest.value;
    req.isLoading = true;

    return axios
      .post(api.userProjects({ userId }), {
        project_ids
      })
      .then(() => {
        getUsersProjectList(userId);
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function changePermissions(projectId, user_id, permission, permission_state) {
    changePermissionsRequest.value = {};
    const req = changePermissionsRequest.value;
    req.isLoading = true;
    req.projectId = projectId;
    req.permissionName = permission;

    return axios
      .post(api.permissions({projectId}), {
        user_id,
        permission,
        permission_state
      })
      .then(() => {
        getUsersProjectList(user_id);
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
        req.projectId = null;
        req.permissionName = null;
      });
  }

  return {
    getPaginatedUsersList,
    createUser,
    getUser,
    getUsersProjectList,
    updateUser,
    userDelete,
    addProjects,
    changePermissions,
    getUserList,
    paginatedUsersList,
    usersProjectList,
    user,
    searchName,
    changePermissionsRequest,
    userList,
    addProjectsRequest,
    createUserRequest,
    getPaginatedUserListRequest
  };
});
