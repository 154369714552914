<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useNodeStore } from '@/stores/node.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import Multiselect from 'vue-multiselect';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { typeList, createInputFieldRequest } = storeToRefs(inputFieldStore);
const childDirections = bpNode.value.child_directions;

const title = ref({});
const type = ref("");
const required = ref(false);
const settings = ref(null);
const order = ref(0);
const child_directions = ref([]);
const selectedChildNodeIds = computed(() => {
  return child_directions.value.map(d => d.next_bp_node_id);
});

onMounted(() => {
  inputFieldStore.clearRequestVariables();
  if (!project.locales) {
    projectStore.getProject(projectId.value);
  }
});

function createInputField() {
  inputFieldStore.createInputField(projectId.value, bpId.value, nodeId.value, title.value, type.value, required.value, settings.value,  selectedChildNodeIds.value)
  .then(() => {
    closeModal();
  });
}

function getName(direction) {
  return direction.next_bp_node.current.node.name;
}
function isErrorField(fieldName) {
  if (createInputFieldRequest.value.error?.errors) {
    return createInputFieldRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createInputFieldRequest.value.error?.errors)) {
    return createInputFieldRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createInputField" :disableButtons="createInputFieldRequest.isLoading">
    <template v-slot:title>Добавить поле для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование поля({{ locale.name }})</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{'error': isErrorField('title')}" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select v-model="type" class="form-select" :class="{'error': isErrorField('type')}">
          <option v-for="typeKey in Object.keys(typeList)" :key="typeKey" :value="typeKey">
            {{ typeList[typeKey] }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <Multiselect
          v-model="child_directions"
          :options="childDirections"
          placeholder="Выбрать..."
          :multiple="true"
          :custom-label="getName"
          track-by="next_bp_node_id"
          class="multiselect-style"
          :class="{ error: isErrorField('child_node_ids') }"
        />
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')">{{ error }}</span>
        </p>
      </div>
      <!-- <div class="mt-3">
        <label class="form-label">Порядок</label>
        <input type="number" v-model="order" class="form-control" min="0"/>
      </div> -->
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
