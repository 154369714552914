<script setup lang="js">
import { defineProps, defineEmits } from 'vue';
import ModalTemplateCenter from '@/components/modals/ModalTemplateCenter.vue';

const emit = defineEmits(['confirmDelete']);
const props = defineProps({
  itemText: String,
  title: {
    type: String,
    default: ''
  }
});
</script>
<template>
  <ModalTemplateCenter @submit="emit('confirmDelete')">
    <template v-slot:title>
      Вы действительно хотите удалить {{ props.itemText }} {{ props.title }}?
    </template>

    <template v-slot:submit @click="emit('confirmDelete')">Удалить</template>
  </ModalTemplateCenter>
</template>
