<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="js">
import { useAutonodeStore } from '@/stores/autonode.store';
import { storeToRefs } from 'pinia';
import { useNodeStore } from '@/stores/node.store';
import useModal from '@/stores/modal.store';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import DirectionModal from '@/components/modals/Autonode/DirectionModal.vue';
import { computed } from 'vue';
import _ from 'lodash';

const nodeStore = useNodeStore();
const autonodeStore = useAutonodeStore();
const { custom_variables, direction_values, selectedDirectionIndex, direction_variable } =
  storeToRefs(autonodeStore);
const { activeModal, closeModal } = useModal();

const directionsVarExists = computed(() => {
  return !!custom_variables.value.find((c) => c.name == direction_variable.value);
});

function getDirectionName(dirId) {
  return (
    nodeStore.bpNode.child_directions?.find((d) => d.id === dirId)?.next_bp_node?.current?.node
      ?.name || 'Узел не найден'
  );
}

function openUpdateModal(index) {
  selectedDirectionIndex.value = index;
  activeModal.value = 'DirectionModal';
}

function openDeleteModal(index) {
  selectedDirectionIndex.value = index;
  activeModal.value = 'deleteModal';
}

function deleteDirection() {
  direction_values.value = _.omit(direction_values.value, selectedDirectionIndex.value);
  closeModal();
}
</script>

<template>
  <div class="autoform-variables">
    <div class="d-flex justify-content-between align-items-center input-btns">
      <div class="flex-nowrap row align-items-center">
        <label class="col-auto" for="direction-var">Выберите переменную для направления</label>
        <select class="col-auto form-select" v-model="direction_variable" id="direction-var">
          <template v-for="cvar in custom_variables" :key="cvar.name">
            <option
              :value="cvar.name"
              v-if="['text', 'double', 'identificator', 'boolean'].includes(cvar.type)"
            >
              {{ cvar.title }}
            </option>
          </template>
        </select>
      </div>
      <div
        v-if="direction_variable && directionsVarExists"
        class="add-btn"
        @click="activeModal = 'DirectionModal'"
      >
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить направление
      </div>
    </div>
    <div></div>
    <div class="table-responsive">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Сравниваемое значение</th>
            <th>Направление</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(directionId, value) in direction_values" :key="value">
            <td>
              {{ value }}
            </td>
            <td>
              {{ getDirectionName(directionId) }}
            </td>
            <td>
              <div class="table-buttons">
                <div class="table-button" @click="openUpdateModal(value)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openDeleteModal(value)">
                  <span class="delite-ico" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DeleteModal
    v-if="activeModal === 'deleteModal'"
    :itemText="'это направление'"
    @confirmDelete="deleteDirection()"
  />
  <DirectionModal v-if="activeModal === 'DirectionModal'" />
</template>
