<script setup lang="js">
import { defineProps, defineEmits, ref } from 'vue';
import ModalTemplateCenter from '@/components/modals/ModalTemplateCenter.vue';

const emit = defineEmits(['confirmDelete']);
const props = defineProps({
  itemText: String,
  title: {
    type: String,
    default: ''
  }
});
const removeDescendants = ref(false);


</script>
<template>
  <ModalTemplateCenter @submit="emit('confirmDelete', removeDescendants)">
    <template v-slot:title>
      <div>Вы действительно хотите удалить {{ props.itemText }} {{ props.title }}?</div>
    </template>

    <template v-slot:body>
      <label for="removeDescendants">
        <input v-model="removeDescendants" type="checkbox" id="removeDescendants" />
        Удалить вместе с потомками
      </label>
    </template>

    <template v-slot:submit @click="emit('confirmDelete', removeDescendants)">Удалить</template>
  </ModalTemplateCenter>
</template>
