<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useProjectStore } from '@/stores/project.store';
import { useUserStore } from '@/stores/user.store';
import Search from '@/components/Search.vue';

const { closeModal } = useModal();
const userStore = useUserStore();
const { usersProjectList } = storeToRefs(userStore);
const userId = useRouteParams('userId');
const selectAllChecked = ref(false);
const projectStore = useProjectStore();
const { projectList, searchName } = storeToRefs(projectStore);
const selectedProjectIds = ref([]);

onMounted(() => {
  projectStore.getProjectList().then(() => {
    if (usersProjectList.value) {
      usersProjectList.value.forEach((userProject) => {
        if (projectList.value.some((project) => project.id === userProject.id)) {
          selectedProjectIds.value.push(userProject.id);
        }
      });
    }
  });
});

function addProject() {
  userStore.addProjects(userId.value, selectedProjectIds.value).then(() => {
    closeModal();
  });
}

const selectAll = () => {
  if (selectAllChecked.value) {
    selectedProjectIds.value = projectList.value.map((project) => project.id);
  } else {
    selectedProjectIds.value = [];
  }
};

const resetAll = () => {
  selectedProjectIds.value = [];
  selectAllChecked.value = false;
};
</script>
<template>
  <ModalTemplateSide @submit="addProject" :disableButtons="userStore.addProjectsRequest.isLoading">
    <template v-slot:title>Добавить проект</template>
    <template v-slot:body>
      <div class="mt-4 d-flex">
        <Search
          @search="projectStore.getProjectList()"
          :value="searchName"
          :placeholderText="'Поиск пользователя'"
          @input="searchName = $event.target.value"
        />
      </div>
      <div class="d-flex justify-content-between project-item mt-3">
        <div class="form-label d-flex align-items-center">
          <input
            type="checkbox"
            class="me-3 modal-checkbox"
            v-model="selectAllChecked"
            @change="selectAll"
          />Выделить все
        </div>
        <div class="form-label" @click="resetAll">Сбросить все</div>
      </div>
      <div class="mt-3 project-item d-flex" v-for="project in projectList" :key="project.id">
        <input
          type="checkbox"
          v-model="selectedProjectIds"
          :value="project.id"
          class="me-1 modal-checkbox"
        />
        <span>{{ project.name }}</span>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
