<script setup lang="js">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSubmoduleStore } from '@/stores/submodule.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const submoduleStore = useSubmoduleStore();
const submoduleGroupId = useRouteParams('submoduleGroupId');
const { closeModal } = useModal();

const { updateCommonSubmoduleRequest, commonSubmodule } = storeToRefs(submoduleStore);

const name = ref(null);
const description = ref(null);
const composable_file = ref(null);

function updateSubmodule() {
  let fd = new FormData();
  fd.append('name', name.value);
  fd.append('description', description.value);
  fd.append('composable_file', composable_file.value);
  submoduleStore.updateCommonSubmodule(submoduleGroupId.value, fd).then(() => {
    closeModal();
  });
}
function isErrorField(fieldName) {
  if (updateCommonSubmoduleRequest.value.error?.errors) {
    return updateCommonSubmoduleRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!updateCommonSubmoduleRequest.value.error?.errors) {
    return updateCommonSubmoduleRequest.value.error;
  }
}
function uploadFile(event) {
  const file = event.target.files[0];
  composable_file.value = file;
}
onMounted(() => {
  if (commonSubmodule.value) {
    name.value = commonSubmodule.value.name;
    description.value = commonSubmodule.value.description;
  }
});
</script>

<template>
  <ModalTemplateSide @submit="updateSubmodule" :disableButtons="updateCommonSubmoduleRequest.isLoading">
    <template v-slot:title>Редактировать субмодуль</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Наименование</label>
        <input v-model="name" type="text" class="form-control" :class="{ error: isErrorField('name') }" />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание</label>
        <textarea v-model="description" class="form-control" :class="{ error: isErrorField('description') }"></textarea>
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Файл настройки</label>
        <div>
          <input type="file" @change="uploadFile($event)" accept=".yml,.yaml" />
        </div>
        <p v-if="isErrorField('composable_file_path')" class="error-mesage">
          <span v-for="error in isErrorField('composable_file_path')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        <span v-for="error in isNotFieldError()">{{ error }}</span>
      </p>
    </template>
    <template v-slot:submit>
      <img v-if="updateCommonSubmoduleRequest.isLoading" class="loader" src="@/assets/img/loader.gif" alt="loader.gif" />
      <div v-else>Сохранить</div>
    </template>
  </ModalTemplateSide>
</template>
