<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useProjectStore } from '@/stores/project.store';

const { closeModal } = useModal();
const inputDocumentsStore = useInputDocumentsStore();
const projectStore = useProjectStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const bpNodeId = useRouteParams('nodeId');
const nodeOpenStates = ref({});
const checkedDoc = ref({});
const switchDocValue = ref({});
const selectAllChecked = ref(false);
const { ancestorsList, viewDocumentsList, createViewDocumentsRequest } = storeToRefs(inputDocumentsStore);
const { project } = storeToRefs(projectStore);

onMounted(() => {
  inputDocumentsStore.clearRequestVariables();
  inputDocumentsStore.getAncestorsList(projectId.value, bpId.value, bpNodeId.value).then(() => {
    if (viewDocumentsList.value && viewDocumentsList.value.length > 0) {
      viewDocumentsList.value.forEach((doc) => {
        checkedDoc.value[doc.input_doc.id] = true;
        switchDocValue.value[doc.input_doc.id] = doc.signable;
      });
    }
  });
});

function toggleNode(bpNodeId) {
  nodeOpenStates.value[bpNodeId] = !nodeOpenStates.value[bpNodeId];
}
function toggleAll() {
  Object.keys(nodeOpenStates.value).forEach((key) => {
    nodeOpenStates.value[key] = false;
  });
}
function selectAll() {
  const newState = selectAllChecked.value;
  ancestorsList.value.forEach((ancestor) => {
    ancestor.input_docs.forEach((doc) => {
      checkedDoc.value[doc.id] = newState;
      if (!newState) {
        switchDocValue[doc.id] = false;
      }
    });
  });
}
function getTitle(title) {
  return title[project.value.default_locale];
}
function create() {
  const creating_input_docs = {};
  const removing_input_doc_ids = [];
  ancestorsList.value.forEach((ancestor) => {
    ancestor.input_docs.forEach((doc) => {
      const wasSelected = doc.id in checkedDoc.value;
      if (checkedDoc.value[doc.id]) {
        const existsInViewDocuments = viewDocumentsList.value.some(
          (viewDoc) => viewDoc.input_doc.id === doc.id
        );
        if (!existsInViewDocuments) {
          creating_input_docs[doc.id] = switchDocValue.value[doc.id] ? true : false;
        }
      } else {
        if (wasSelected) {
          removing_input_doc_ids.push(doc.id);
          switchDocValue.value[doc.id] = false;
        }
      }
    });
  });
  inputDocumentsStore
    .createViewDocuments(
      projectId.value,
      bpId.value,
      bpNodeId.value,
      creating_input_docs,
      removing_input_doc_ids
    )
    .then(() => {
      closeModal();
    });
}
function isRequestError() {
  if (!(createViewDocumentsRequest.value.error?.errors)) {
    return createViewDocumentsRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="create" :disableButtons="createViewDocumentsRequest.isLoading">
    <template v-slot:title>Добавить документ для просмотра</template>
    <template v-slot:body>
      <p v-if="isRequestError()" class="error-mesage">
        {{ isRequestError() }}
      </p>
      <div class="d-flex justify-content-between">
        <div class="form-label d-flex align-items-center">
          <input
            type="checkbox"
            class="me-3 modal-checkbox"
            v-model="selectAllChecked"
            @change="selectAll"
          />Выделить все
        </div>
        <div class="form-label" @click="toggleAll">
          Свернуть все
          <img src="@/assets/img/minus-ico-with-border.svg" class="ms-3" />
        </div>
      </div>
      <div
        class="d-flex justify-content-between flex-column"
        v-for="ancestor in ancestorsList"
        :key="ancestor.id"
      >
        <div class="modal-nodes d-flex justify-content-between">
          <span> {{ ancestor.current.node.name }}</span>
          <img
            src="@/assets/img/plus-ico-with-border.svg"
            @click="toggleNode(ancestor.current.id)"
            v-if="!nodeOpenStates[ancestor.current.id]"
          />
          <img
            src="@/assets/img/minus-ico-with-border.svg"
            @click="toggleNode(ancestor.current.id)"
            v-if="nodeOpenStates[ancestor.current.id]"
          />
        </div>
        <div v-if="nodeOpenStates[ancestor.current.id]" class="node-docs">
          <div class="d-flex justify-content-between">
            <span>Название документа</span>
            <span>Подпись</span>
          </div>
          <div
            v-for="inputDoc in ancestor.input_docs"
            :key="inputDoc.id"
            class="node-doc-list d-flex justify-content-between align-items-start mt-3"
          >
            <div class="d-flex align-items-center">
              <input
                type="checkbox"
                v-model="checkedDoc[inputDoc.id]"
                class="me-1 modal-checkbox"
              />
              <span>{{ getTitle(inputDoc.document.title) }}</span>
            </div>
            <basic-toggle-switch
              v-model="switchDocValue[inputDoc.id]"
              :disabled="!checkedDoc[inputDoc.id]"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
