<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRegistryStore } from '@/stores/registry.store';
import { useNodeStore } from '@/stores/node.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import Multiselect from 'vue-multiselect';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const registryStore = useRegistryStore();
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { regTables } = storeToRefs(registryStore);
const { createInputCatalogRequest } = storeToRefs(inputFieldStore);
const childDirections = bpNode.value.child_directions;

const selectedRegTable = ref(null);
const title = ref({});
const type = ref('simple');
const parent_column = ref(null);
const title_columns = ref({});
const required = ref(false);
const child_directions = ref([]);
const filteredRegTables = computed(() => regTables.value.filter((registry) => registry.stable));
const selectedChildNodeIds = computed(() => {
  return child_directions.value.map(d => d.next_bp_node_id);
});
onMounted(() => {
  if (!regTables.value.length) {
    registryStore.getRegTableList(projectId.value);
  }
  inputFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
});

function createCatalog() {
  inputFieldStore
    .createInputCatalog(
      projectId.value,
      bpId.value,
      nodeId.value,
      selectedRegTable.value?.id,
      selectedRegTable.value?.last_version,
      title.value,
      title_columns.value,
      required.value,
      selectedChildNodeIds.value,
      type.value,
      parent_column.value,
    )
    .then(() => {
      closeModal();
    });
}

function getName(direction) {
  return direction.next_bp_node.current.node.name;
}
function isErrorField(fieldName) {
  if (createInputCatalogRequest.value.error?.errors) {
    return createInputCatalogRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!createInputCatalogRequest.value.error?.errors) {
    return createInputCatalogRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createCatalog" :disableButtons="createInputCatalogRequest.isLoading">
    <template v-slot:title>Добавить справочник</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование поля({{ locale.name }})</label>
        <input
          type="text"
          v-model="title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
        />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')" :key="error">
          {{ error }}
        </span>
      </p>
      <div class="mt-3">
        <label class="form-label">Тип справочника</label>
        <select v-model="type" class="form-select" :class="{ error: isErrorField('type') }">
          <option value="simple" selected>Простой</option>
          <option value="multilevel">Многоуровневый</option>
        </select>
      </div>
      <p v-if="isErrorField('type')" class="error-mesage">
        <span v-for="error in isErrorField('type')" :key="error">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Реестр</label>
        <Multiselect
          v-model="selectedRegTable"
          :options="filteredRegTables"
          placeholder="Выбрать..."
          :multiple="false"
          label="id"
          track-by="id"
          class="multiselect-style"
          :class="{ error: isErrorField('selectedRegTable') }"
        />
        <p v-if="isErrorField('reg_table_id')" class="error-mesage">
          <span v-for="error in isErrorField('reg_table_id')" :key="error">{{ error }}</span>
        </p>
      </div>
      <template v-if="selectedRegTable">
        <div v-if="type === 'multilevel'" class="mt-3">
          <label class="form-label">Родительская колонка</label>
          <select
            v-model="parent_column"
            class="form-select"
            :class="{ error: isErrorField('parent_column') }"
          >
            <option
              v-for="column in selectedRegTable.columns"
              :key="column.id"
              :value="String(column.id)"
            >
              {{ column.name }}
            </option>
          </select>
        </div>

        <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
          <label class="form-label">Колонка({{ locale.name }})</label>
          <select
            v-model="title_columns[locale.id]"
            class="form-select"
            :class="{ error: isErrorField('title_columns') }"
          >
            <option
              v-for="column in selectedRegTable.columns"
              :key="column.id"
              :value="String(column.id)"
            >
              {{ column.name }}
            </option>
          </select>
        </div>
      </template>
      <p v-if="isErrorField('title_columns')" class="error-mesage">
        <span v-for="error in isErrorField('title_columns')" :key="error">{{ error }}</span>
      </p>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <Multiselect
          v-model="child_directions"
          :options="childDirections"
          placeholder="Выбрать..."
          :multiple="true"
          :custom-label="getName"
          track-by="next_bp_node_id"
          class="multiselect-style"
          :class="{ error: isErrorField('child_node_ids') }"
        />
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')" :key="error">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
