<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { onMounted, ref, defineProps, watch } from 'vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import { useUserStore } from '@/stores/user.store';

const userStore = useUserStore();
const { closeModal } = useModal();
const newPassword = ref('');
const confirmPassword = ref('');
const adminPanel = ref(false);
const projectCreate = ref(false);
const permissions = ref([]);
const props = defineProps({
  selectedUser: Object
});

onMounted(() => {
  if (props.selectedUser.permissions?.length === 0) {
    adminPanel.value = false;
    projectCreate.value = false;
  } else {
    props.selectedUser.permissions?.forEach((user) => {
      if (user.name === 'admin_panel') {
        adminPanel.value = true;
      }
      if (user.name === 'create_project') {
        projectCreate.value = true;
      }
    });
  }
});

function updatePermissions() {
  if (adminPanel.value) {
    if (!permissions.value.includes('admin_panel')) {
      permissions.value.push('admin_panel');
    }
  } else {
    const index = permissions.value.indexOf('admin_panel');
    if (index > -1) {
      permissions.value.splice(index, 1);
    }
  }

  if (projectCreate.value) {
    if (!permissions.value.includes('create_project')) {
      permissions.value.push('create_project');
    }
  } else {
    const index = permissions.value.indexOf('create_project');
    if (index > -1) {
      permissions.value.splice(index, 1);
    }
  }
}

watch(adminPanel, updatePermissions);
watch(projectCreate, updatePermissions);

function edit() {
  userStore
    .updateUser(
      props.selectedUser.id,
      props.selectedUser.name,
      props.selectedUser.email,
      newPassword.value,
      confirmPassword.value,
      permissions.value
    )
    .then(() => {
      closeModal();
    });
}
</script>
<template>
  <ModalTemplateSide @submit="edit" :disableButtons="userStore.getPaginatedUserListRequest.isLoading">
    <template v-slot:title>Редактировать пользователя</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Фамилия и Имя пользователя</label>
        <input type="text" v-model="props.selectedUser.name" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">E-mail</label>
        <input type="text" v-model="props.selectedUser.email" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">Новый пароль</label>
        <input type="text" v-model="newPassword" class="form-control" />
      </div>
      <div class="mt-3">
        <label class="form-label">Повторите пароль</label>
        <input type="text" v-model="confirmPassword" class="form-control" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Доступ к панели «Администрирование»</span>
        <basic-toggle-switch v-model="adminPanel" />
      </div>
      <div class="mt-3 d-flex justify-content-between">
        <span class="form-label">Создание проекта</span>
        <basic-toggle-switch v-model="projectCreate" />
      </div>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
